import styled from "styled-components";

export const Container = styled.div`
display: flex;
height: 100vh;
align-items: center;
justify-content: center;
`

export const Button = styled.button`
  outline: none;
  font-size: 18px;
  padding: 14px 18px;
  cursor: pointer;
`;
